import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  CrmBreakpointSpanDirective,
  CrmElementIdPipe,
  CrmGutter,
} from 'common-module/core';
import { CrmRendererDirective } from 'common-module/renderer';
import { groupBy } from 'lodash-es';
import { NzGridModule } from 'ng-zorro-antd/grid';

import { GridContent, GridOrientation } from './grid.model';

@Component({
  standalone: true,
  selector: 'app-grid',
  template: `
    <ng-container [ngSwitch]="orientation">
      <ng-container *ngSwitchCase="'vertical'">
        <div nz-row [nzGutter]="gutter">
          @for (contents of columns; track $index) {
            <div nz-col crmBreakpointSpan [breakpointConfig]="colSpan">
              @for (col of contents; track col.id) {
                <div [id]="col.id | crmElementId: 'grid'">
                  <ng-container [crmRenderer]="col"></ng-container>
                </div>
              }
            </div>
          }
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        @for (contents of rows; track $index) {
          <div nz-row [nzGutter]="gutter">
            @for (col of contents; track col.id) {
              <div
                nz-col
                crmBreakpointSpan
                [breakpointConfig]="col.span ?? 24"
                [offset]="col.offset ?? 0"
                [id]="col.id | crmElementId: 'grid'"
              >
                <ng-container [crmRenderer]="col"></ng-container>
              </div>
            }
          </div>
        }
      </ng-container>
    </ng-container>
  `,
  imports: [
    NzGridModule,
    CrmRendererDirective,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    CrmBreakpointSpanDirective,
    CrmElementIdPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent implements OnInit {
  @Input() gutter: CrmGutter = [16, 16];
  @Input() orientation: GridOrientation = 'horizontal';
  @Input() contents: GridContent<unknown>[] = [];

  // internal vertical data
  colSpan = 24;
  columns: GridContent<unknown>[][] = [];

  // internal horizontal data
  rows: GridContent<unknown>[][] = [];

  ngOnInit() {
    this.buildGrid();
  }

  protected buildGrid() {
    switch (this.orientation) {
      case 'vertical':
        this.buildVerticalGrid();
        break;
      default:
        this.buildHorizontalGrid();
        break;
    }
  }

  protected buildVerticalGrid() {
    this.columns = Object.values(groupBy(this.contents, 'col'));
    this.colSpan = 24 / this.columns.length;
  }

  protected buildHorizontalGrid() {
    this.rows = Object.values(groupBy(this.contents, 'row'));
  }
}
