import { Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CrmDictionary } from 'common-module/core/types';
import { BehaviorSubject, Subject } from 'rxjs';

export type FilterStateModel = {
  data: unknown;
  view: FilterViewItem[] | null;
};

export type FilterViewItem = {
  category?: string;
  label: string;
  value: unknown;
  key?: string;
};

export type ViewModelItem = FilterViewItem & { index: string };

@Injectable()
export class FilterState {
  readonly data = signal<CrmDictionary>({});
  readonly model$ = new BehaviorSubject<CrmDictionary<FilterStateModel | null>>(
    {},
  );

  private actionEmitters = {
    reset: new BehaviorSubject<number>(0),
    remove: new Subject<ViewModelItem>(),
  };

  readonly actions = {
    reset: toSignal(this.actionEmitters.reset),
    remove: toSignal(this.actionEmitters.remove),
  };

  setModelValue(index: string, model: FilterStateModel | null) {
    this.model$.next({ ...this.model$.value, [index]: model });
  }

  reset() {
    this.actionEmitters.reset.next(this.actionEmitters.reset.value + 1);
  }

  remove(item: ViewModelItem) {
    this.actionEmitters.remove.next(item);
  }
}
