<app-grid [contents]="viewModel.fields()"></app-grid>

@if (viewModel.view(); as view) {
  @if (view.enabled) {
    <div class="filter-selected">
      <nz-space>
        @for (vo of view.items; track vo.track) {
          <nz-tag *nzSpaceItem>
            @if (vo.category) {
              <span style="margin-right: 4px"
                >{{ vo.category | crmTranslate }}:</span
              >
            }

            <strong>{{ vo.label | crmTranslate }}</strong>
            <span
              nz-icon
              nzType="icons:close-line"
              style="margin-left: 4px; cursor: pointer"
              (click)="state.remove(vo)"
            ></span>
          </nz-tag>
        }
      </nz-space>

      <span class="reset">
        <button nz-button nzSize="small" nzType="link" (click)="state.reset()">
          {{ 'generic.resetFilters' | crmTranslate }}
        </button>
      </span>
    </div>
  }
}
